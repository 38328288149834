//Actions related to login, logout to Application
export const ACCOUNT__LOGIN = createActionType('ACCOUNT__LOGIN');
export const ACCOUNT__LOGOUT = createActionType('ACCOUNT__LOGOUT');
//...........................
export const SEARCH_CUTTING = createActionType('SEARCH_CUTTING');
export const CREATE_CUTTING = createActionType('CREATE_CUTTING')
export const UPDATE_CUTTING = createActionType('UPDATE_CUTTING')
export const DELETE_CUTTING = createActionType('DELETE_CUTTING');
export const RETRIEVE_TOKEN = createActionType('RETRIEVE_TOKEN');

export function createAction({ action, headers = {}, type }) {
  return async (dispatch) => {
    dispatch({ type: type.REQUEST, headers });
    try {
      dispatch({ type: type.SUCCESS, headers, payload: await action() });
    } catch (error) {
      dispatch({ type: type.FAILURE, headers, error });
    }
  };
}
export function createActionType(id) {
  return {
    FAILURE: `${id}__FAILURE`,
    REQUEST: `${id}__REQUEST`,
    SUCCESS: `${id}__SUCCESS`,
  };
}