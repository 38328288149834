import baseState from '../store/baseState';
import { CREATE_CUTTING, DELETE_CUTTING, SEARCH_CUTTING, UPDATE_CUTTING, RETRIEVE_TOKEN } from '../actions/action';
export default (state = baseState.machineCutting, { payload, type, error }) => {
    switch (type) {
        case SEARCH_CUTTING.REQUEST:
            return {
                ...state
            };
        case SEARCH_CUTTING.SUCCESS:
            return {
                ...state,
                searchCutting: payload.data
            };
        case SEARCH_CUTTING.FAILURE:
            return {
                ...state,
                searchCutting: {},
                error: error.message
            };
        case CREATE_CUTTING.REQUEST:
            return {
                ...state
            };
        case CREATE_CUTTING.SUCCESS:
            return {
                ...state,
                addCutting: payload.data
            };
        case CREATE_CUTTING.FAILURE:
            return {
                ...state,
                addCutting: {},
                error: error.message
            };
        case UPDATE_CUTTING.REQUEST:
            return {
                ...state
            };
        case UPDATE_CUTTING.SUCCESS:
            return {
                ...state,
                updatedCutting: payload.data
            };
        case UPDATE_CUTTING.FAILURE:
            return {
                ...state,
                updatedCutting: {},
                error: error.message
            };
        case DELETE_CUTTING.REQUEST:
            return {
                ...state
            };
        case DELETE_CUTTING.SUCCESS:
            return {
                ...state,
                deleteCutting: payload.data
            };
        case DELETE_CUTTING.FAILURE:
            return {
                ...state,
                deleteCutting: {},
                error: error.message
            };

        case RETRIEVE_TOKEN.REQUEST:
            return {
                ...state
            };
        case RETRIEVE_TOKEN.SUCCESS:
            return {
                ...state,
                retrievedData: payload.data
            };
        case RETRIEVE_TOKEN.FAILURE:
            return {
                ...state,
                retrievedData: {},
                error: error.message
            };
        default:
            return state;
    }
};