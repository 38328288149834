import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "./common/Spinner";

const ScrewFlightData = lazy(() => import("./persons/MachineCutting"));
const Welcome = lazy(() => import("./welcome/Welcome"));

class AppRoutes extends Component {
  state = {};

  componentDidMount() { }

  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/screw-flights-data" component={ScrewFlightData} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
